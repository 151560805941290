angular.module('app.controllers').controller('PasswordCtrl', ['$scope', '$rootScope', 'APICheckin', '$timeout', '$location', 'APIConfirm', 'UI'].append(function(self, root, APICheckin, $timeout, $location, APIConfirm, UI) {
  var params;
  self.step = 1;
  self.formSuccess = false;
  self.passType = true;
  self.passType2 = true;
  self.togglePass = function(pass = true) {
    if (pass) {
      return self.passType = !self.passType;
    }
    return self.passType2 = !self.passType2;
  };
  params = $location.search();
  (self.resetData = function() {
    self.data = {
      email: '',
      hash: '',
      password: ''
    };
    self.fake = {
      password: ''
    };
    return self.formProccess = false;
  })();
  self.saveForm = function(form) {
    return self.fForm = form;
  };
  
  // Request new password

  self.forgotPass = function() {
    if (!self.data.email) {
      return;
    }
    self.formProccess = true;
    return (new APICheckin()).$save({
      action: 'password_lost',
      email: self.data.email
    }, function(data) {
      self.resetData();
      self.formSuccess = true;
      return self.formProccess = false;
    }, function(response) {
      self.data.email = '';
      self.formSuccess = false;
      self.formProccess = false;
      return UI.notify(false, response.data.error);
    });
  };
  
  // Save new password

  if (params && angular.isDefined(params.password_restore)) {
    self.step = 2;
    self.data.hash = params.password_restore;
    self.data.email = params.email;
  }
  self.savePass = function() {
    if (!self.data.email) {
      return;
    }
    if (!self.data.password) {
      return;
    }
    if (!self.data.hash) {
      return;
    }
    self.formProccess = true;
    return (new APICheckin(self.data)).$save({
      action: 'password_restore'
    }, function(data) {
      self.resetData();
      self.formSuccess = true;
      return self.formProccess = false;
    }, function(response) {
      self.formSuccess = false;
      self.formProccess = false;
      return UI.notify(false, response.data.error);
    });
  };
  
  // Set new password

  if (params && angular.isDefined(params.auth_ticket)) {
    self.step = 3;
    self.auth_ticket = params.auth_ticket;
  }
  self.setPass = function() {
    if (self.auth_ticket == null) {
      return;
    }
    self.formProccess = true;
    return (new APICheckin({
      password: self.data.password
    })).$save({
      _private: true,
      auth_ticket: self.auth_ticket
    }, function(data) {
      self.resetData();
      self.formSuccess = true;
      return self.formProccess = false;
    }, function(response) {
      self.formSuccess = false;
      self.formProccess = false;
      return UI.notify(false, response.data.error);
    });
  };
  return self.forgotAction = function() {
    //return unless self.isState('logstart')
    if (self.step === 1) {
      self.forgotPass();
    }
    if (self.step === 2) {
      self.savePass();
    }
    if (self.step === 3) {
      self.setPass();
    }
  };
}));
