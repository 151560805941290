angular.module('app.controllers').controller 'PasswordCtrl', [
  '$scope', '$rootScope', 'APICheckin', '$timeout', '$location', 'APIConfirm', 'UI'
].append (self, root, APICheckin, $timeout, $location, APIConfirm, UI) ->

  self.step = 1

  self.formSuccess = false
  

  self.passType  = true
  self.passType2 = true
  self.togglePass = (pass = true) ->
    return self.passType = !self.passType if pass
    self.passType2 = !self.passType2

  params = $location.search()

  (self.resetData = ->
    self.data = 
      email: ''
      hash: ''
      password: ''

    self.fake = 
      password: ''

    self.formProccess = false
  )()

  self.saveForm = (form) ->
    self.fForm = form


  #
  # Request new password
  #
  
  self.forgotPass = () ->
    return unless self.data.email

    self.formProccess = true

    (new APICheckin).$save {action: 'password_lost', email: self.data.email}, (data) ->
      self.resetData()
      self.formSuccess  = true
      self.formProccess = false
    , (response) ->
      self.data.email   = ''
      self.formSuccess  = false
      self.formProccess = false
      UI.notify false, response.data.error


  #
  # Save new password
  #

  if params && angular.isDefined(params.password_restore)
    self.step = 2

    self.data.hash  = params.password_restore
    self.data.email = params.email

  self.savePass = () ->
    return unless self.data.email
    return unless self.data.password
    return unless self.data.hash

    self.formProccess = true

    (new APICheckin self.data).$save {action: 'password_restore'}, (data) ->
      self.resetData()
      self.formSuccess = true 
      self.formProccess = false     
    , (response) -> 
      self.formSuccess = false
      self.formProccess = false
      UI.notify false, response.data.error
            
  #
  # Set new password
  #

  if params && angular.isDefined(params.auth_ticket)
    self.step = 3
    self.auth_ticket = params.auth_ticket

  self.setPass = () ->
    return unless self.auth_ticket?
    self.formProccess = true    
    (new APICheckin {password: self.data.password}).$save {_private: true, auth_ticket: self.auth_ticket}, (data) ->
      self.resetData()
      self.formSuccess = true
      self.formProccess = false
    , (response) ->
      self.formSuccess = false
      self.formProccess = false
      UI.notify false, response.data.error

  self.forgotAction = () ->
    #return unless self.isState('logstart')

    if self.step == 1
      self.forgotPass()
    if self.step == 2
      self.savePass()
    if self.step == 3
      self.setPass()

    return
